@import "../../../variables";
@import "bootstrap/scss/mixins/breakpoints";

.up-4-items-carousel {
    &.margins-enabled {
        margin-top: 30px;

        @include media-breakpoint-up(md) {
            margin-top: 36px;
        }

        @include media-breakpoint-up(lg) {
            margin-top: 32px;
        }

        @include media-breakpoint-up(xl) {
            margin-top: 28px;
        }
    }
}

.up-4-items-carousel-carousel-wrapper {
    position: relative;

    .swiper-button-prev {
        @include media-breakpoint-up(lg) {
            left: -12px;
        }

        @include media-breakpoint-up(xl) {
            left: -56px;
        }
    }

    .swiper-button-next {
        @include media-breakpoint-up(lg) {
            right: -12px;
        }

        @include media-breakpoint-up(xl) {
            right: -56px;
        }
    }

    .hide-arrow {
        display: none;

        @include media-breakpoint-up(lg) {
            display: flex;
        }
    }
}

.hide-bullets {
    display: block;

    @include media-breakpoint-up(lg) {
        display: none;
    }
}
